// src/components/Message.js
import React from 'react';
import './Message.css';

function Message({ status, text }) {
  let messageClass;

  if (!text) {
    return null;
  }
  
  switch (status) {
    case 200:
    case 204:
      messageClass = 'success'; // Sucesso
      break;
    case 400:
    case 404:
    case 402:
      messageClass = 'error'; // Erro do cliente
      break;
    case 500:
      messageClass = 'error'; // Erro do servidor
      break;
    default:
      messageClass = 'message'; // Cor padrão para outros casos
  }
  
  return (
    <div>      
      <p className={`${messageClass}`}>{text}</p>
    </div>
  );
}

export default Message;
