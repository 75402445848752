//src/components/ResetPasswordForm.js
import React, { useState } from 'react';
import api from '../services/api';
import Spinner from './Spinner';
import Message from './Message';
import logo from '../assets/login/solubio1.png';
import './ResetPasswordForm.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

function verificaRequisitosSenha(senha) {
  return {
    comprimento: senha.length >= 8 && senha.length <= 10,
    letraMaiuscula: /[A-Z]/.test(senha),
    letraMinuscula: /[a-z]/.test(senha),
    digito: /[0-9]/.test(senha),
    caracterNaoAlfanumerico: /[^A-Za-z0-9]/.test(senha),
  };
}


function ResetPasswordForm({onBackToTokenForm  }) {
  const [token, setToken] = useState('');
  const [UserPassword, setUserPassword] = useState('');
  const [UserPasswordConfirm, setUserPasswordConfirm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [messageStatus, setMessageStatus] = useState(''); 
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [setIsPasswordConfirmFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [requisitosSenha, setRequisitosSenha] = useState({
    comprimento: false,
    letraMaiuscula: false,
    letraMinuscula: false,
    digito: false,
    caracterNaoAlfanumerico: false,
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [mostrarRequisitos, setMostrarRequisitos] = useState(false);

  const handlePasswordFocus = () => {
    setIsPasswordFocused(true);
  };

  const handlePasswordConfirmFocus = () => {
    setIsPasswordConfirmFocused(true);
  };


  const handlePasswordChange = (e) => {
    const novaSenha = e.target.value;
    setUserPassword(novaSenha);
    setRequisitosSenha(verificaRequisitosSenha(novaSenha));
    setMostrarRequisitos(!message, verificaRequisitosSenha(novaSenha));
  };

  const handlePasswordConfirmChange = (e) => {
    const confirmSenha = e.target.value;
    setUserPasswordConfirm(confirmSenha);
    setRequisitosSenha(verificaRequisitosSenha(confirmSenha));
    setMostrarRequisitos(!message, verificaRequisitosSenha(confirmSenha));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requisitosAtendidos = Object.values(requisitosSenha).every(Boolean);
    if (!requisitosAtendidos) {
      setMessage('A senha não atende a todos os requisitos necessários.');
      setMessageStatus('error');

      // Adiciona o timeout para limpar a mensagem
      setTimeout(() => {
        setMessage('');
      }, 5000);

      return; // Impede a submissão do formulário
    }
    setIsLoading(true);
    setMessage('');
    setMessageStatus('');
    try {
      const response = await api.patch('/resetPassword', { token, UserPassword });
      setMessage(response.data.message);
      setMessageStatus(response.status);
      setTimeout(() => {
        setMessage(''); // Limpa a mensagem
      }, 5000); // Duração da mensagem: 2000ms ou 2 segundos
    } catch (error) {
      let errorMessage = error.response && error.response.data && error.response.data.error
                        ? error.response.data.error
                        : 'Erro ao redefinir senha. Tente novamente.';
      setMessage(errorMessage);
      setMessageStatus(error.response ? error.response.status : 500);
    
      setTimeout(() => {
        setMessage(''); // Limpa a mensagem
      }, 5000);
    } finally {
      setIsLoading(false);
    }
  };
  
  return (
    <>
      {/* Container principal */}
      <div className="container-full">
            <div className="row justify-content-center">
              <div className="col-8">
                <div className="card border border-light-subtle rounded-3 shadow-sm card-custom" >
                  <div className="card-body p-3 p-md-4 p-xl-5">
                            
                              {/* Logo */}
                              <div className="text-center mb-3 image">
                                <img src={logo} alt="Logo" width="350" className="img-fluid" />
                              </div>
                  
                              <div className="rounded-div">
                                <h6>Reset de senha SAP</h6>
                                <p>Informe o token enviado para o seu e-mail.</p>    
                                <p id="label">
                                  O token gerado possui validade de 2 minutos. Caso expire, será necessário gerar um novo token.
                                </p>      
                                <a href="/" className="btn btn-primary btn-token btn-sm"><i class="fa-solid fa-key"></i> Gerar novo token</a>            
                              </div>  

                              <br />
                
                              {/* Formulário */}
                              <form onSubmit={handleSubmit}>
                                  <div className="row g-3">
                    
                                    {/* Campo Token de Usuário */}
                                    <div className="col-12">
                                      <div className="form-floating">
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="token"
                                          placeholder="Informe o token"
                                          value={token}
                                          onChange={(e) => setToken(e.target.value)}
                                          required
                                        />     
                                        <label htmlFor="token">Token</label>
                                      </div>
                                    </div>

                                    {/* Campo Nome de Usuário */}
                                   <div className="col-12">
                                      <div className="form-floating position-relative">
                                          <input
                                            type={showPassword ? "text" : "password"}
                                            className="form-control"
                                            id="password"
                                            placeholder="Nova Senha"
                                            value={UserPassword}
                                            onChange={handlePasswordChange}
                                            onFocus={handlePasswordFocus}
                                            required
                                          />
                                          <label htmlFor="password">Nova Senha</label>  
                                          <div class="input-group-append">
                                                <span 
                                                className="position-absolute end-0 top-50  translate-middle-y pe-3 cursor-pointer" 
                                                onClick={togglePasswordVisibility}
                                                style={{ cursor: "pointer" }}
                                              >
                                                {showPassword ? '👁️' : '👁️‍🗨️'}
                                              </span>     
                                            </div>                                                                      
                                        </div>
                                    </div> 

                                    {/* Campo Nome de Usuário */}
                                   <div className="col-12">
                                        <div className="form-floating position-relative">
                                            <input
                                              type={showPassword ? "text" : "password"}
                                              className="form-control"
                                              id="password-confirm"
                                              placeholder="Confirme a Senha"
                                              value={UserPasswordConfirm}
                                              onChange={handlePasswordConfirmChange}
                                              onFocus={handlePasswordConfirmFocus}
                                              required
                                            />
                                            <label htmlFor="password">Confirme a semha</label> 
                                            <div class="input-group-append">
                                                <span 
                                                className="position-absolute end-0 top-50  translate-middle-y pe-3 cursor-pointer" 
                                                onClick={togglePasswordVisibility}
                                                style={{ cursor: "pointer" }}
                                              >
                                                {showPassword ? '👁️' : '👁️‍🗨️'}
                                              </span>     
                                            </div>                                    
                                          </div>
                                    </div>  
                                    
                    
                                    {/* Botão de Solicitação */}
                                    <div className="col-12">
                                      <div className="d-grid">
                                        {isLoading ? (
                                          <Spinner />
                                        ) : (
                                          <button 
                                          className="btn btn-primary btn-lg" 
                                          type="submit"
                                          disabled={
                                            !token ||
                                            !Object.values(requisitosSenha).every(Boolean) || // Verifica se todos os requisitos são atendidos
                                            UserPassword !== UserPasswordConfirm || // Verifica se as senhas são iguais
                                            isLoading // Desabilita o botão enquanto está carregando
                                          }>
                                            Atualizar senha
                                          </button>
                                        )}
                                      </div>
                                    </div> 
                                  </div>
                              </form>

                              {isPasswordFocused && !isLoading && !message && (
                                <div className="requisitos-senha">
                                    {/* Renderiza a lista de requisitos somente se não estiver carregando */}
                                    {mostrarRequisitos && (
                                      <ul>
                                        <li style={{ color: requisitosSenha.comprimento ? 'green' : 'red' }}>Entre 8 e 10 caracteres</li>
                                        <li style={{ color: requisitosSenha.letraMaiuscula ? 'green' : 'red' }}>1 letra maiúscula</li>
                                        <li style={{ color: requisitosSenha.letraMinuscula ? 'green' : 'red' }}>1 letra minúscula</li>
                                        <li style={{ color: requisitosSenha.digito ? 'green' : 'red' }}>1 dígito</li>
                                        <li style={{ color: requisitosSenha.caracterNaoAlfanumerico ? 'green' : 'red' }}>1 caractere não alfanumérico</li>
                                      </ul>
                                    )}                                    
                                </div>
                              )}

                              <br />

                              {/* Verificação e Mensagem de Erro */}
                              {UserPasswordConfirm && UserPassword !== UserPasswordConfirm && !isLoading && (
                                <div>
                                  {mostrarRequisitos && (
                                    <div>
                                      <p className="error">As senhas não são iguais.</p>
                                    </div>
                                  )}
                                </div>
                              )}
                              
                              {/* Renderiza a mensagem somente se não estiver carregando */}
                              {!isLoading && message && (
                                <div className="mensagem-resposta">
                                  <Message status={messageStatus} text={message} />
                                </div>
                              )}
                             
                          </div>
                    </div>
              </div>
          </div>
      </div>
    </>
  );  

}

export default ResetPasswordForm;


