//src/components/RequestTokenForm.js
import React, { useState } from 'react';
import api from '../services/api';
import Spinner from './Spinner';
import ResetPasswordForm from './ResetPasswordForm';
import Message from './Message';
import logo from '../assets/login/solubio1.png';
import './ResetPasswordForm.css';

function RequestTokenForm({ onTokenRequested }) {
  const [username, setUserName] = useState('');
  const [CompanyDB, setCompanyDB] = useState(''); 
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [messageStatus, setMessageStatus] = useState(''); 
  const [showResetPassword, setShowResetPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage('');
    setMessageStatus('');
    try {
      const response = await api.post('/getUser', { username, CompanyDB });
      setMessage(response.data.message);
      setMessageStatus(response.status);
      setTimeout(() => {
        setShowResetPassword(true);
        setMessage(''); 
        setMessageStatus('');
      }, 5000); // Duração da mensagem: 2000ms ou 2 segundos
    } catch (error) {
      const errorMessage = error.response && error.response.data && error.response.data.error
                          ? error.response.data.error
                          : 'Erro ao solicitar token. Tente novamente.';
      setMessage(errorMessage);
      setMessageStatus(error.response ? error.response.status : 500);
      setTimeout(() => {
        setMessage(''); 
        setMessageStatus('');
      }, 5000);
    } finally {
      setIsLoading(false);
    }
  };

  if (showResetPassword) {
    return <ResetPasswordForm />;
  }

  /*return (
    <>
    <div className="image-container">
      <img src={logo} alt="Logo" />
    </div>
  
    <div>
        <form onSubmit={handleSubmit}>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <input type="text" value={username} onChange={(e) => setUserName(e.target.value)} placeholder="Nome de usuário" />
              <select
                className="select-custom"
                value={CompanyDB}
                onChange={(e) => setCompanyDB(e.target.value)}
              >
                <option value="">Selecione uma Base de Dados</option>
                <option value="SBO_SOLUBIO_PRD_NEW">01-[PRODUCAO]</option>
                <option value="SBO_SOLUBIO_TST2">02-[HOMOLOCACAO]</option>
                <option value="SBO_SOLUBIO_TST10">10-[DESENVOLVIMENTO]</option>
              </select>
              <button type="submit">Solicitar Token</button>
            </>
          )}
        </form>
        {!isLoading && <Message status={messageStatus} text={message} />}
      </div></>
  );*/

  return (
    <> 
      {/* Container verde ocupando 100% da tela */}
      <div className="container-full">
        <div className="row justify-content-center">
          <div className="col-8">
            <div className="card border border-light-subtle rounded-3 shadow-sm card-custom" >
              <div className="card-body p-3 p-md-4 p-xl-5">
                {/* Logo */}
                <div className="text-center mb-3 image">
                  <img src={logo} alt="Logo" width="350" />
                </div>

                <div className="rounded-div">
                  <h6>Reset de senha SAP</h6>
                  <p>Informe o nome de usuário e a base de dados associada à sua
                  conta para solicitar um token.</p>                  
                </div>  

                <br />
  
                {/* Formulário */}
                <form onSubmit={handleSubmit}>
                  <div className="row gy-2 overflow-hidden">
                    {/* Campo Nome de Usuário */}
                    <div className="col-12">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="username"
                          placeholder="Nome de usuário"
                          value={username}
                          onChange={(e) => setUserName(e.target.value)}
                          required
                        />
                        <label htmlFor="username" className="form-label">
                          Nome de Usuário
                        </label>
                      </div>
                    </div>
  
                    {/* Campo Base de Dados */}
                    <div className="col-12">
                      <div className="form-floating">
                        <select
                          className="form-select"
                          id="CompanyDB"
                          value={CompanyDB}
                          onChange={(e) => setCompanyDB(e.target.value)}
                          required
                        >
                          <option value="">Selecione uma Base de Dados</option>
                          <option value="SBO_SOLUBIO_PRD_NEW">
                            01-[PRODUCAO]
                          </option>
                          <option value="SBO_SOLUBIO_TST2">02-[HOMOLOCACAO]</option>
                          <option value="SBO_SOLUBIO_TST10">
                            10-[DESENVOLVIMENTO]
                          </option>
                        </select>
                        <label htmlFor="CompanyDB" className="form-label">
                          Base de Dados
                        </label>
                      </div>
                    </div>
  
                    {/* Botão Solicitar Token */}
                    <div className="col-12">
                      <div className="d-grid my-3">
                        {isLoading ? (
                          <Spinner />
                        ) : (
                          <button
                            className="btn btn-primary btn-lg"
                            type="submit"
                          >
                            Solicitar Token
                          </button>
                        )}
                      </div>
                    </div>
  
                    {/* Mensagens */}
                    <div className="col-12">
                      {!isLoading && (
                        <Message status={messageStatus} text={message} />
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  
  
  


}

export default RequestTokenForm;
